<template>
  <v-container class="pa-5">
                            <div style="height:200px">
 
                            <v-row class="rowh" style="margin-left:22px">
                                <v-btn color="primary" @click="triggerFileInput" class="mb-4" icon :disabled="!edit_file">
                                    <v-icon>mdi-upload</v-icon>
                                    Attach Files
                                </v-btn>
                            </v-row>
                            <v-row><br>
                                <v-file-input ref="fileInput" multiple style="display: none" @change="handleFileUpload" />
                                   
                                    <v-row dense no-gutters>
                                            <v-progress-circular
                                            v-if="uploading"
                                            indeterminate
                                            :size="30"
                                            :width="7"
                                            color="purple"
                                            ></v-progress-circular>
                                        <v-col dense v-for="(file, index) in uploadedFiles" :key="index" cols="auto">
                                            <v-chip v-if="edit_file && file.active==1" close @click:close="removeFile(index)" color="primary" class="ma-2" dark>
                                                <a href="#" @click.prevent="downloadFile(file)" style="color: white; text-decoration: none">
                                                    {{ file.name.replace(".zip", '') }}
                                                </a>
                                            </v-chip>
                                              <v-chip v-else-if="file.active==1"  color="primary" class="ma-2" dark>
                                                  <a href="#" @click.prevent="downloadFile(file)" style="color: white; text-decoration: none">
                                                    {{ file.name.replace(".zip", '') }}
                                                </a>
                                                </v-chip>
                                        </v-col>
                                    </v-row>
                              
                            </v-row>
                            </div>
                        </v-container>
</template>
<script>
 import JSZip from "jszip";
import { api } from "print/data.js";
export default {
  components: {},
  props: {
    edit_file: {type:Boolean,default:true},
    uploading_file: {type:Boolean,default:false},
    files_list: Array, 

  },
  data() {
    return { 
      uploadedFiles: [],
      edit: true, 
      uploading: false, 
    };
  },
  watch: {},
  async mounted() { 
      this.uploadedFiles=this.files_list;
      this.uploading=this.uploading_file;
      this.edit=this.edit_file;
  },
  async created() {},
  methods: {
         downloadFile(file) {

            let xhr = new XMLHttpRequest();
        xhr.open("GET", api + file.url, true);
        xhr.responseType = "blob";  

        xhr.onload = async function () {
            if (this.status === 200) {
            try { 
            const JSZip = require("jszip");
            const zip = await JSZip.loadAsync(this.response); 
                            for (const filename of Object.keys(zip.files)) {
                                const zipEntry = zip.file(filename);
                                if (zipEntry) { 
                                    const content = await zipEntry.async("blob"); 
                                    const blob = new Blob([content], { type: "application/octet-stream" });
                                    const link = document.createElement("a");
                                    link.href = window.URL.createObjectURL(blob); 
                                    link.download = filename.replace(/(\.\w+)?$/, "_extracted$1") || file.filename;
                                    
                                    link.click(); 
                                    window.URL.revokeObjectURL(link.href);
                                }
                            }
             } catch (error) {
                       //     console.error("Erreur lors de la décompression du fichier :", error);
             }
                    } else {
                       // console.error("Erreur lors du téléchargement :", this.statusText);
                    }
                }; 
                xhr.send(); 
        },
        triggerFileInput() {
            this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        async handleFileUpload(files) {

           for (const file of Array.from(files)) { 
                if (this.uploadedFiles.every(x => x.name !== `${file.name}.zip`)) {
                    const zip = new JSZip();
                    zip.file(file.name, file); 
                    const content = await zip.generateAsync({
                        type: "blob",
                        compression: "DEFLATE",
                        compressionOptions: { level: 9 }
                    });

                    const zipFileName = `${file.name}.zip`; 
                            this.uploadedFiles.push({
                                name: zipFileName,
                                content: content,
                                active: 1
                            });
                    }
           } 
           this.$emit("change_files", this.uploadedFiles);
        },
        removeFile(index) {
        //  console.log(this.uploadedFiles.filter(x=>x.active==1));
           this.uploadedFiles[index].active = 0; 
            this.$emit("change_files", this.uploadedFiles);  
             
        },
  },
};
</script>
<style scoped>
.scrollable-container {
    max-height: 600px;
    /* Hauteur maximum du conteneur */
    overflow-y: auto;
    /* Active le scroll vertical */
    padding: 10px;
    margin-top: 10px;
}

.rowh {
    margin-left: 12px;
    height: 25px !important;
}

.vrow {
    flex-wrap: wrap;
}
</style>
